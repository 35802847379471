import useStyles from "./styles";
import CustomButton from "../CutomButton";

interface TagButtonProps {
  name: string;
  onClick: () => void;
  className?: string;
  selected?: boolean;
}

export default function TagButton({
  name,
  onClick,
  className,
  selected,
}: TagButtonProps): JSX.Element {
  const { classes: s } = useStyles();
  const cn = [s.root];
  if (className) {
    cn.push(className);
  }
  return (
    <CustomButton
      className={cn.join(" ")}
      onClick={onClick}
      label={name}
      variant={selected ? "contained" : "outlined"}
    />
  );
}
TagButton.defaultProps = {
  className: "",
  selected: false,
};
