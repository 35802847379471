import { Box, Grid } from "@mui/material";

type LoginContainerProps = {
  children: React.ReactElement;
  imageUrl?: string;
};

export default function LoginContainer({
  children,
  imageUrl = "/illustration/hero.jpg",
}: LoginContainerProps) {
  return (
    <Box
      sx={{
        background: "#d1d1e9",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        overflowY: "scroll",
        minHeight: "700px",
      }}
    >
      <Grid
        container
        maxWidth="lg"
        minHeight={{ sm: "auto", lg: "80vh" }}
        margin={"auto"}
        sx={{ background: "white", borderRadius: 8, margin: 4 }}
      >
        <Grid
          item
          xs={0}
          sm={6}
          md={7}
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            justifyContent: "center",
            px: 4,
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: 8,
          }}
        />
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}
