import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "25px 30px",
    borderRadius: "10px",
    color: "#2b2c34",
    width: "100%",
    maxWidth: 400,
  },
  form: {
    width: "100%",
  },
  link: {
    fontWeight: 600,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
  loginBtn: {
    width: "100%",
    marginBottom: 4,
    marginTop: 4,
  },
  termsContainer: {
    fontSize: "12px",
  },
  errorMsg: {
    color: "red",
    fontSize: "12px",
    padding: "10px 5px",
  },

  signupContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    alignItems: "center",
    fontSize: "14px",
  },
  logo: {
    maxWidth: 180,
    marginBottom: 36,
  },
}));

export default useStyles;
