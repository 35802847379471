import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { gql } from "../../__generated__";
import ProfileContainer from "../../containers/ProfileContainer";
import PageLoader from "../../components/PageLoader";
import ChatWrapper from "../../components/Chat/ChatWrapper";
import { UserContext } from "../../Context";

export default function ProfilePage(): JSX.Element {
  const { userId } = useParams();
  const { user: loggedUser } = useContext(UserContext);
  const profileOwnerId = Number(userId);

  const { data, loading } = useQuery(USER_PROFILE, {
    variables: { userId: Number(userId) },
    skip: !userId || !Number(userId),
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <PageLoader />;
  }
  if (data?.profileByUserId && data?.profileByUserId?.blogs?.[0]?.id) {
    return (
      <div>
        <ProfileContainer
          blogId={data.profileByUserId?.blogs?.[0]?.id}
          blogDetails={data.profileByUserId.blogs[0]}
          userDetails={data.profileByUserId}
        />
        {loggedUser?.userId !== profileOwnerId && (
          <ChatWrapper
            profileOwnerDetails={{
              profileOwnerId,
              avatarImage: data.profileByUserId.profile.avatarImage || "",
              userName: data.profileByUserId.userName,
            }}
          />
        )}
      </div>
    );
  }
  return <div>Profile not found</div>;
}

const USER_PROFILE = gql(`
  query ProfileByUserId($userId:Int!){
      profileByUserId(userId:$userId){
        userId
        email
        userName
        privacyLevel
        taggable
        mentionable
        createdAt
        profile{
          userId
          firstName
          lastName
          userName
          createdAt
          avatarImage
        }
        siteSettings{
          type
          value
        } 
        blogs{
          id
          name
          title
          about
          userId
          blogType
          theme
          privacyLevel
          privacyLevelInfo{
            id
            name
          }
          themeInfo{
            id
            name
          }
          themeMediaInfo{
            blogId
            themeId
            url
          }
          followingCount
          friendsCount
          followersCount 
          createdAt
        } 
      }
    }
`);
