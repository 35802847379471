import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import MobileDrawer from "./mobileDrawer";
// import MobileView from "./mobileView";
import DesktopView from "./desktopView";
import { UserContext } from "../../Context";
import { LOCAL_KEY } from "../../utils/constants";
import ADMIN_ROUTES from "../../utils/adminRoutes";

type SettingTypes = {
  label: string;
  onClick?: () => void;
  isAdmin?: boolean;
  isModerator?: boolean;
  roles: string[];
};

interface NavigationBarProps {
  changeMode: (mode: string) => void;
  mode: string;
}

const NavigationBar = ({
  changeMode,
  mode,
}: NavigationBarProps): JSX.Element => {
  const { user } = React.useContext(UserContext);
  // hooks
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  // functions
  const handleOpenSettingsMenu = (
    event: React.MouseEvent<HTMLElement>
  ): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
  };

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  function logOut(): void {
    // Log out the user
    localStorage.clear();
    window.location.replace("/");
  }

  function goToSettings(): void {
    navigate("/settings");
  }

  const settings: SettingTypes[] = [
    {
      label: mode === "light" ? "Dark Mode" : "Light Mode",
      onClick: () => {
        changeMode(mode === "light" ? "dark" : "light");
        handleCloseUserMenu();
      },
      roles: ["admin", "moderator", "loggedUser"],
    },
    {
      label: "Settings",
      onClick: () => {
        goToSettings();
        handleCloseUserMenu();
      },
      roles: ["admin", "moderator", "loggedUser"],
    },
    {
      label: "Admin Dashboard",
      onClick: () => {
        localStorage.setItem(LOCAL_KEY.ADMIN_DASHBOARD, "true");
        window.location.replace(ADMIN_ROUTES.USERS);
      },
      roles: ["admin"],
    },
    {
      label: "Moderator Dashboard",
      onClick: () => {
        localStorage.setItem(LOCAL_KEY.ADMIN_DASHBOARD, "true");
        window.location.replace(ADMIN_ROUTES.USERS);
      },
      roles: ["moderator"],
    },
    {
      label: "Log out",
      onClick: () => logOut(),
      roles: ["admin", "moderator", "loggedUser"],
    },
  ];

  const pages = ["Latest", "Friends", "Following", "Highlights", "Discussions"];

  let userRole = "loggedUser";
  const isAdmin = user?.isAdmin;
  const isModerator = user?.isModerator;
  if (isAdmin) userRole = "admin";
  if (isModerator) userRole = "moderator";

  const filteredSettings = settings.filter((s) => s.roles.includes(userRole));
  const userDetails = user;

  return (
    <div>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          mb: "2rem",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* mobile view */}
            {/* <MobileView
              setMobileOpen={() => setMobileOpen(true)}
              userDetails={userDetails}
              handleOpenSettingsMenu={handleOpenSettingsMenu}
            /> */}

            {/* desktop view */}
            <DesktopView
              // pages={pages}
              userDetails={userDetails}
              handleOpenSettingsMenu={handleOpenSettingsMenu}
            />

            {/* settings drop down */}
            <Box sx={{ flexGrow: 0 }}>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {filteredSettings.map((setting) => (
                  <MenuItem
                    key={setting.label}
                    onClick={setting.onClick}
                    sx={{ bgcolor: "background.default" }}
                  >
                    <Typography textAlign="center">{setting.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <MobileDrawer
        pages={pages}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
    </div>
  );
};
export default NavigationBar;
