import { Badge, IconButton } from "@mui/material";
import { useState, MouseEvent, useEffect, useContext } from "react";
import { MdOutlineMessage } from "react-icons/md";

import useStyles from "./styles";
import MessageList from "../Chat/MessageList";
import { gql } from "../../__generated__";
import { useQuery, useSubscription } from "@apollo/client";
import { UserContext } from "../../Context";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";

const NotificationIcon = () => {
  const [count, setCount] = useState(0);
  const { user } = useContext(UserContext);
  const { classes: s } = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useQuery(UNREAD_MESSAGES, {
    onCompleted: (data) => {
      setCount(data.loggedUserUnreadMessageCount.count);
    },
  });

  const { data: subscription } = useSubscription(MESSAGES_COUNT, {
    variables: { userId: user?.userId || 0 },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });

  useEffect(() => {
    setCount(subscription?.userUnreadMessageCount.count || 0);
  }, [subscription]);

  return (
    <>
      <IconButton
        size="large"
        color="primary"
        className={s.iconButton}
        onClick={handleClick}
      >
        <Badge badgeContent={count} color="error">
          <MdOutlineMessage />
        </Badge>
      </IconButton>
      <MessageList anchorEl={anchorEl} handleClose={handleClose} />
    </>
  );
};
export default NotificationIcon;

const UNREAD_MESSAGES = gql(`
query LoggedUserUnreadMessageCount {
  loggedUserUnreadMessageCount {
    count
  }
}
`);

const MESSAGES_COUNT = gql(`
subscription UserUnreadMessageCount($userId: Int!) {
  userUnreadMessageCount(userId: $userId) {
    count
  }
}
`);
