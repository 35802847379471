/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

import { UserContext } from "../../Context";
import ROUTES from "../../utils/routes";
import LoginForm from "../../components/LoginForm/LoginForm";
import LoginContainer from "../../containers/LoginContainer/LoginContainer";

export default function LogInPage(): JSX.Element {
  const userContext = useContext(UserContext);
  const location = useLocation();
  // hooks
  const navigate = useNavigate();

  useEffect(() => {
    if (userContext?.user?.isCompleted) {
      navigate(ROUTES.DASHBOARD);
    } else if (userContext.user?.userId) {
      navigate(ROUTES.COMPLETE_SIGN_IN);
    }
  }, [userContext, navigate]);

  return (
    <LoginContainer>
      <LoginForm />
    </LoginContainer>
  );
}
