import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Divider } from "@mui/material";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { gql } from "../../__generated__";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import CustomButton from "../../components/CutomButton";
import ReactHooksInput from "../../components/ReactHooksInput";
import LoginContainer from "../../containers/LoginContainer/LoginContainer";

import useStyles from "../../components/LoginForm/LoginFromStyles";

const schema = Yup.object()
  .shape({
    password: Yup.string().required().min(8).max(15).label("Password"),
    confirmPassword: Yup.string().required().label("Confirm Password"),
  })
  .required();

type FormData = Yup.InferType<typeof schema>;

export default function ResetPasswordPage(): JSX.Element {
  const { classes: s } = useStyles();
  const history = useNavigate();
  const params = useParams();

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      toast.success("You have successfully reset your password");
      history("/");
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleResetPassword(data: {
    password: string;
    confirmPassword: string;
  }): void {
    resetPassword({
      variables: {
        token: params.token || "",
        password: data.password,
      },
    });
  }

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitted },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  return (
    <LoginContainer>
      <div className={s.root}>
        <img src={"/logo.svg"} alt="logo" className={s.logo} />
        <form onSubmit={handleSubmit(handleResetPassword)} className={s.form}>
          <Box sx={{ mb: 1 }}>
            <ReactHooksInput
              label="Password"
              name="password"
              required
              inputType="password"
              placeholder="Password"
              size="lg"
              register={register}
              error={errors.password?.message}
              touched={touchedFields.password || isSubmitted}
            />
          </Box>
          <Box sx={{ mb: 1 }}>
            <ReactHooksInput
              label="ConfirmPassword"
              name="confirmPassword"
              required
              inputType="password"
              placeholder="Confirm Password"
              size="lg"
              register={register}
              error={errors.confirmPassword?.message}
              touched={touchedFields.confirmPassword || isSubmitted}
            />
          </Box>

          {/* {error && <div className={s.errorMsg}>{graphqlErrorToMsg(error)}</div>} */}

          <CustomButton
            className={s.loginBtn}
            variant="contained"
            type="submit"
            loading={loading}
            label="Reset Password"
            size="lg"
            onClick={() => {}}
          />
          <Box sx={{ my: 2 }}>
            <Divider>or</Divider>
          </Box>
          <CustomButton
            className={s.loginBtn}
            size="lg"
            variant="outlined"
            label="Sign In"
            onClick={() => {
              history("/");
            }}
          />
        </form>
      </div>
    </LoginContainer>
  );
}

const RESET_PASSWORD = gql(`
mutation resetPassWordByToken($token:String!,$password:String!){
  resetPassWordByToken(token:$token,password:$password){
    message
  }
}
`);
