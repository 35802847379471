import { Box, FormControl, FormHelperText } from "@mui/material";
import useStyles from "./styles";
import { UseFormRegister } from "react-hook-form";

interface ReactHooksInputProps {
  error?: any;
  placeholder?: string;
  inputType?: string;
  disabled?: boolean;
  label?: string;
  name: string;
  touched: boolean | undefined;
  register: UseFormRegister<any>;
  required?: boolean;
  autoFocus?: boolean;
  size?: "sm" | "md" | "lg";
}
export default function ReactHooksInput({
  inputType,
  error,
  placeholder,
  disabled,
  label,
  name,
  touched,
  register,
  required,
  autoFocus,
  size,
}: ReactHooksInputProps): JSX.Element {
  const { classes: s } = useStyles();

  const cn = [s.input];

  if (size === "lg") {
    cn.push(s.large);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", pb: 2 }}>
      {label && (
        <FormHelperText className={s.label} sx={{ mx: 0 }}>
          {label}
        </FormHelperText>
      )}
      <FormControl>
        {inputType === "textarea" ? (
          <textarea
            {...register(name)}
            name={name}
            className={cn.join(" ")}
            placeholder={placeholder}
            rows={6}
          />
        ) : (
          <input
            {...register(name, { required })}
            name={name}
            disabled={disabled}
            type={inputType || "text"}
            className={cn.join(" ")}
            placeholder={placeholder}
            autoFocus={autoFocus}
          />
        )}
        {error && touched && (
          <FormHelperText className={s.error} sx={{ mx: 0 }}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}

ReactHooksInput.defaultProps = {
  error: null,
  placeholder: "",
  inputType: "text",
  disabled: false,
  label: "",
  required: false,
};
