import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      fontSize: 14,
      borderRadius: 24,
      textTransform: "capitalize",
    },
    disabled: {
      color: `${theme.palette.primary.light} !important`,
      cursor: "not-allowed",
      background: `${theme.palette.primary.main} !important`,
    },
    large: {
      padding: 12,
      borderRadius: 30,
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        padding: 8,
        borderRadius: 24,
        fontSize: 14,
      },
    },
  };
});

export default useStyles;
