import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Box } from "@mui/material";

import ReactHooksInput from "../ReactHooksInput";
import useStyles from "./LoginFromStyles";
import ROUTES from "../../utils/routes";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { gql } from "../../__generated__";
import CustomButton from "../CutomButton";

type LoginFormProps = {};
const schema = yup
  .object()
  .shape({
    userName: yup.string().required().label("Username"),
    password: yup.string().required(),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const LoginForm = (props: LoginFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes: s } = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitted },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [login, { loading, error }] = useMutation(LOGIN, {
    onCompleted: (apiRes) => {
      const searchParams = new URLSearchParams(location.search);
      const from = searchParams.get("from");

      if (apiRes.login?.token) {
        localStorage.setItem("accessToken", apiRes.login.token);
        if (from) {
          const prevUrl = `${window.location.origin}${from}`;
          window.location.replace(prevUrl);
        } else if (apiRes.login?.isAdmin || apiRes.login?.isModerator) {
          window.location.replace(ROUTES.ADMIN_DASHBOARD);
        } else if (apiRes.login.account.profile.firstName) {
          window.location.replace(ROUTES.DASHBOARD);
        } else {
          window.location.replace(ROUTES.COMPLETE_SIGN_IN);
        }
      }
    },
  });

  const signInUser = (values: FormData) => {
    login({ variables: { email: values.userName, password: values.password } });
  };

  return (
    <div className={s.root}>
      <img src={"/logo.svg"} alt="logo" className={s.logo} />
      <form onSubmit={handleSubmit(signInUser)} className={s.form}>
        <Box sx={{ mb: 1 }}>
          <ReactHooksInput
            label="Username / Email"
            name="userName"
            required
            placeholder="Username / Email"
            register={register}
            error={errors.userName?.message}
            touched={touchedFields.userName || isSubmitted}
            size="lg"
            autoFocus
          />
        </Box>

        <Box sx={{ mb: 1 }}>
          <ReactHooksInput
            label="Password"
            name="password"
            required
            inputType="password"
            placeholder="Password"
            size="lg"
            register={register}
            error={errors.password?.message}
            touched={touchedFields.password || isSubmitted}
          />
        </Box>

        {error && <div className={s.errorMsg}>{graphqlErrorToMsg(error)}</div>}

        <CustomButton
          className={s.loginBtn}
          variant="contained"
          type="submit"
          loading={loading}
          label="Sign In"
          size="lg"
          onClick={() => {}}
        />
      </form>
      <div className={s.signupContainer}>
        <Box
          className={s.link}
          onClick={() => {
            navigate("/forgot-password");
          }}
        >
          Forgot your password?
        </Box>
      </div>
      <div className={s.signupContainer}>
        New to Social cluster?
        <Box
          className={s.link}
          onClick={() => {
            navigate("/signup");
          }}
        >
          Sign up
        </Box>
      </div>
    </div>
  );
};

export default LoginForm;

const LOGIN = gql(`
    mutation login($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        isModerator
        isAdmin
        account {
          email
          profile {
            firstName
          }
        }
        token
      }
    }
  `);
