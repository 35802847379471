import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Divider } from "@mui/material";

import useStyles from "../../components/LoginForm/LoginFromStyles";
import { Box } from "@mui/system";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import ReactHooksInput from "../../components/ReactHooksInput";
import CustomButton from "../../components/CutomButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LoginContainer from "../../containers/LoginContainer/LoginContainer";

interface ResetPasswordInterface {
  email: string;
}

type FormData = Yup.InferType<typeof schema>;

const schema = Yup.object()
  .shape({
    email: Yup.string().required().email().label("Email"),
  })
  .required();
export default function ForgotPassword(): JSX.Element {
  const { classes: s } = useStyles();
  const history = useNavigate();

  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      toast.success("Please check your email to reset the password");
    },
    onError: (error) => toast.error(graphqlErrorToMsg(error)),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function resetPassword(values: ResetPasswordInterface): void {
    forgotPassword({ variables: { email: values.email } });
  }

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  return (
    <LoginContainer>
      <div className={s.root}>
        <img src={"/logo.svg"} alt="logo" className={s.logo} />
        <form onSubmit={handleSubmit(resetPassword)} className={s.form}>
          <Box sx={{ mb: 1 }}>
            <ReactHooksInput
              label="Email"
              name="email"
              required
              inputType="email"
              placeholder="Email"
              register={register}
              error={errors.email?.message}
              touched={touchedFields.email}
              size="lg"
              autoFocus
            />
          </Box>

          {/* {error && <div className={s.errorMsg}>{graphqlErrorToMsg(error)}</div>} */}

          <CustomButton
            className={s.loginBtn}
            variant="contained"
            type="submit"
            loading={loading}
            label="Reset Password"
            size="lg"
            onClick={() => {}}
          />
          <Box sx={{ my: 2 }}>
            <Divider>Or</Divider>
          </Box>
          <CustomButton
            className={s.loginBtn}
            size="lg"
            variant="outlined"
            label="Sign In"
            onClick={() => {
              history("/");
            }}
          />
        </form>
      </div>
    </LoginContainer>
  );
}

const FORGOT_PASSWORD = gql(`
mutation requestPassWordReset($email:String!){
  requestPassWordReset(email:$email){
    message
  }
}
`);
