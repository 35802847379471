import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import CardButtons from "../../containers/PostCardContainer/cardButtons";
import useStyles from "./styles";
import { Post } from "../../__generated__/graphql";
import { Box } from "@mui/system";
import { getPostTitle } from "../../utils/postHelper";
import { lightBackgroundColor } from "../../utils/themes";

interface PostCardProps {
  postData: Post;
  image: string | null;
  isLikedPost?: boolean;
}

export default function PostCard({
  postData,
  image,
  isLikedPost,
}: PostCardProps): JSX.Element {
  const { classes: s } = useStyles();
  const navigate = useNavigate();

  function routeToIndividualPost(): void {
    navigate(`/post/${postData.id}`);
  }

  const isWallpaperTheme = true;

  const cn = [s.root];
  if (isWallpaperTheme) {
    cn.push(s.wallpaper);
  }

  return (
    <Card
      className={cn.join(" ")}
      sx={{
        mb: 4,
      }}
    >
      <Grid container>
        <Grid
          item
          xs={4}
          sx={{ position: "relative", display: "flex", alignItems: "center" }}
        >
          <CardMedia
            style={{ cursor: "pointer", zIndex: 5, borderRadius: 3 }}
            component="img"
            // height={isLikedPost ? "auto" : "300px"}
            image={image || ""}
            alt="cover"
            onClick={() => routeToIndividualPost()}
          />
          <Box
            sx={{
              position: "absolute",
              height: "100%",
              width: "100%",
              filter: "blur(8px)",
              top: 0,
              zIndex: 1,
              backgroundColor: lightBackgroundColor,
            }}
          >
            <CardMedia
              style={{ cursor: "pointer" }}
              component="img"
              height={"100%"}
              image={image || ""}
              alt="cover"
              onClick={() => routeToIndividualPost()}
              // sx={{ position: "absolute", , zIndex: 0 }}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                fontWeight: 600,
                fontSize: "22px",
                textTransform: "capitalize",
                mb: 2,
              }}
            >
              {getPostTitle(
                postData.title,
                postData.isShared,
                postData.shareText
              )}
            </Typography>
            <Typography variant="body2">{postData.description}</Typography>
          </CardContent>
          {/* comment and likes */}
          <CardActions sx={{ mb: 2 }}>
            <CardButtons
              id={postData.id}
              userBlogId={null}
              post={postData}
              commentsCount={postData.comments?.length || 0}
              tabIndex={0}
              routeToIndividualPost={() => routeToIndividualPost}
              isAlreadyliked={!!postData.isLike}
            />
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
}
PostCard.defaultProps = {
  isLikedPost: false,
};
