import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    input: {
      border: `1px solid ${theme.palette.text.primary}`,
      color: theme.palette.text.primary,
      padding: 12,
      borderRadius: 24,
      "&:focus-visible": {
        outline: `1px solid ${theme.palette.text.primary}`,
      },
      "&::placeholder": {
        color: theme.palette.text.primary,
        opacity: 1,
      },
      background:
        theme.palette.mode === "dark"
          ? "#023047"
          : theme.palette.background.default,
    },
    error: {
      color: "#e45858 !important",
      padding: "0 2px",
    },
    label: { color: theme.palette.text.primary, fontSize: 14 },
    large: {
      borderRadius: 30,
      padding: 16,
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        borderRadius: 24,
        padding: 12,
        fontSize: 12,
      },
    },
  };
});

export default useStyles;
