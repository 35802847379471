import { Button, CircularProgress, SxProps, Theme } from "@mui/material";
import useStyles from "./styles";

interface CustomButtonProps {
  label: string;
  onClick: () => void;
  className?: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  variant?: "text" | "contained" | "outlined";
  sx?: SxProps<Theme>;
  loading?: boolean;
  size?: "sm" | "md" | "lg";
}
export default function CustomButton({
  label,
  onClick,
  className,
  type = "button",
  disabled,
  variant,
  sx,
  loading,
  size = "md",
}: CustomButtonProps): JSX.Element {
  const { classes: s } = useStyles();

  const cn = [s.root];
  if (className) {
    cn.push(className);
  }
  if (disabled) {
    cn.push(s.disabled);
  }
  if (size === "lg") {
    cn.push(s.large);
  }

  const loaderSize = size === "lg" ? 25 : 20;

  return (
    <Button
      className={cn.join(" ")}
      onClick={onClick}
      type={type}
      disabled={disabled}
      variant={variant || "contained"}
      sx={sx}
    >
      {loading ? (
        <CircularProgress size={loaderSize} sx={{ color: "white" }} />
      ) : (
        label
      )}
    </Button>
  );
}

CustomButton.defaultProps = {
  className: "",
};
