import React, { useContext, useEffect, useRef, useState } from "react";
import { Avatar, Badge, Box, IconButton, Typography } from "@mui/material";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { motion } from "motion/react";

import "./ChatApp.css";
import MessageInput from "./MessageInput";
import useStyles from "./ChatWindowStyles";
import { MessageType, ProfileOwnerDetailsType } from "./types";
import { UserContext } from "../../Context";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";

interface ChatWindowProps {
  unreadCount: number;
  messages: MessageType[];
  profileOwnerDetails: ProfileOwnerDetailsType;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  unreadCount,
  messages,
  profileOwnerDetails: { profileOwnerId, userName, avatarImage },
}) => {
  const { classes: s } = useStyles();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [hideChat, toggelChat] = useState(true);
  const [unreadMsgCount, setUnreadCount] = useState(unreadCount);
  const { user: loggeduser } = useContext(UserContext);

  // Auto-scroll to the bottom when messages change
  useEffect(() => {
    // Scroll to the bottom of the messages container
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    onError: (err) => {
      toast.error(graphqlErrorToMsg(err));
    },
  });
  const [markAsRead] = useMutation(MARK_AS_READ, {
    onError: (err) => {
      toast.error(graphqlErrorToMsg(err));
    },
    onCompleted: () => setUnreadCount(0),
  });

  const handleSend = (message: string) => {
    // no need to update parent component it will
    // automatically update when subscription trigger
    sendMessage({
      variables: {
        info: {
          receiverId: profileOwnerId,
          content: message,
        },
      },
    });
  };
  // trigger handleMarkAsRead when user open the chat
  // or when new massage recieve
  useEffect(() => {
    handleMarkAsRead();
  }, [messages, hideChat]);

  const handleMarkAsRead = () => {
    if (!hideChat && messages.length) {
      const recivedMsg = messages.filter((x) => x.senderId === profileOwnerId);
      if (recivedMsg.length) {
        const lastMsgId = recivedMsg.slice(-1)[0].id;

        markAsRead({ variables: { messageId: lastMsgId } });
      }
    }
  };

  return (
    <Box className={s.root}>
      <Box
        className={s.headerSection}
        onClick={() => {
          toggelChat((s) => !s);
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {avatarImage ? (
            <Avatar src={avatarImage} sx={{ width: 30, height: 30, mr: 1 }} />
          ) : (
            <Avatar sx={{ width: 30, height: 30, mr: 1 }}>
              {userName.charAt(0).toUpperCase()}
            </Avatar>
          )}
          <Typography variant="h6" fontSize={14}>
            {userName}
            <Badge badgeContent={unreadMsgCount} color="error" sx={{ ml: 2 }} />
          </Typography>
        </Box>
        <Box>
          <IconButton color="inherit" sx={{ p: 0 }}>
            {hideChat ? <FaChevronUp size={16} /> : <FaChevronDown size={16} />}
          </IconButton>
        </Box>
      </Box>

      <motion.div
        animate={{
          height: hideChat ? 0 : "auto", // Scale to minimize or maximize
        }}
        transition={{ duration: 0.1, ease: "easeInOut" }}
      >
        <Box className={s.messageBox}>
          {messages.map((msg, idx) => {
            const isLoggedUserMsg = loggeduser?.userId === msg.senderId;
            return (
              <Box
                key={idx}
                className={s.messageTextBox}
                sx={{
                  justifyContent: isLoggedUserMsg ? "flex-end" : "flex-start",
                }}
              >
                <Box
                  style={{
                    backgroundColor: isLoggedUserMsg ? "#cce5ff" : "#e2e3e5",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <Typography fontSize={14}>{msg.content}</Typography>
                </Box>
              </Box>
            );
          })}
          <div ref={messagesEndRef}></div>
        </Box>
        <MessageInput onSendMessage={handleSend} />
      </motion.div>
    </Box>
  );
};

export default ChatWindow;

const SEND_MESSAGE = gql(`
  mutation SendMessage($info: SendMessageInput!) {
    sendMessage(info: $info) {
      content
      id
      receiverId
      senderId
    }
  }
  `);

const MARK_AS_READ = gql(`
mutation MarkAsRead($messageId: Int!) {
  markAsRead(messageId: $messageId) {
    message
  }
}`);
