import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Avatar } from "@mui/material";
import CustomInput from "../../../../components/CustomInput";
import useStyles from "./styles";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import FileUploader from "../../../../components/FileUploader";
import CustomButton from "../../../../components/CutomButton";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name").min(4).max(255),
  lastName: Yup.string().required().label("Last Name").min(4).max(255),
});

type UserMoreDetails = {
  firstName: string;
  lastName: string;
  dob?: Date | null;
  blogName: string;
  theme: number;
  image: File | null;
  blogImage: File | null;
};

interface Props {
  next: () => void;
  userMoreDetails: UserMoreDetails;
  setUserMoreDetails: (value: any) => void;
}

export default function FormOne(props: Props): JSX.Element {
  const { next, userMoreDetails, setUserMoreDetails } = props;
  const [preview, setPreview] = useState<any>();
  const [imageFile, setImageFile] = useState<File | null>(
    userMoreDetails.image || null
  );

  const { classes: s } = useStyles();

  React.useEffect(() => {
    if (!imageFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(imageFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl);
  }, [imageFile]);

  return (
    <div className={s.formWrapper}>
      <Formik
        initialValues={{
          firstName: userMoreDetails?.firstName || "",
          lastName: userMoreDetails?.lastName || "",
          dob: userMoreDetails?.dob || "",
          blogName: userMoreDetails.blogName || "",
        }}
        onSubmit={(values: any) => {
          setUserMoreDetails({
            ...userMoreDetails,
            ...values,
            image: imageFile,
          });
          next();
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          const {
            errors,
            touched,
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          } = formikProps;

          return (
            <div>
              <p className={s.title}>Create your profile</p>
              {preview ? (
                <Avatar className={s.avatar} src={preview} />
              ) : (
                <FileUploader
                  accept="image/png, image/jpeg"
                  component={<Avatar className={s.avatar} src={""} />}
                  onChange={(file) => setImageFile(file)}
                />
              )}
              <CustomInput
                name="firstName"
                placeholder="First Name"
                label="First Name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.firstName || ""}
                touched={!!touched.firstName}
              />

              <CustomInput
                name="lastName"
                placeholder="Last Name"
                label="Last Name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.lastName}
                touched={!!touched.lastName}
              />

              <CustomDatePicker
                label="Date of birth"
                name="dob"
                placeHolder="Date of birth"
                startDate={values?.dob ? new Date(values.dob) : null}
                onChange={(date) => setFieldValue("dob", date)}
                onBlur={handleBlur}
                touched={!!touched.dob}
              />
              <CustomButton
                className={s.nextBtn}
                size="lg"
                label="Create Profile"
                variant="contained"
                onClick={() => {
                  handleSubmit();
                }}
                type="submit"
              />
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
