import React, { useState } from "react";
import { Box, TextField, IconButton } from "@mui/material";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { GrEmoji } from "react-icons/gr";
import CustomButton from "../CutomButton";

interface MessageInputProps {
  onSendMessage: (message: string) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState<string>("");
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message);
      setShowEmojiPicker(false);
      setMessage("");
    }
  };
  const handleEmojiSelect = (emoji: any) => {
    setMessage(message + emoji.native);
  };

  return (
    <>
      {showEmojiPicker && (
        <Box sx={{ position: "fixed", bottom: 85, background: "white" }}>
          <Picker
            data={data}
            onEmojiSelect={handleEmojiSelect}
            style={{ position: "absolute", bottom: "60px", right: "10px" }}
            previewPosition="none"
            navPosition="top"
          />
        </Box>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid #ddd",
          padding: "10px",
        }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type a message..."
            variant="outlined"
          />
          <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <GrEmoji />
          </IconButton>
          <CustomButton
            onClick={handleSend}
            label="Send"
            disabled={!message}
            sx={{ marginLeft: "10px" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default MessageInput;
