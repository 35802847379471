import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { lightBackgroundColor } from "../../../utils/themes";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      paddingTop: 0,
    },
    postButton: {
      padding: "5px 50px",
      fontSize: 18,
      borderRadius: 30,
    },
    links: {
      padding: "8px !important",
    },
    linkWrapper: {
      backgroundColor: lightBackgroundColor,
      borderRadius: 8,
    },
    wallpaper: {
      backgroundColor:
        theme.palette.mode === "dark" ? "#151515 !important" : "#fff",
    },
  };
});

export default useStyles;
