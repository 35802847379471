import {
  Avatar,
  Badge,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { gql } from "../../__generated__";
import { UserContext } from "../../Context";
import { UserInfoTypes } from "./types";
import ROUTES from "../../utils/routes";

type MessageListProps = {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
};

export default function MessageList({
  anchorEl,
  handleClose,
}: MessageListProps) {
  const open = Boolean(anchorEl);
  const { data, loading, error, refetch } = useQuery(LATEST_MESSAGE_LIST, {
    fetchPolicy: "network-only",
  });

  // refetch query when user open the Menu
  useEffect(() => {
    if (anchorEl) {
      refetch();
    }
  }, [anchorEl, refetch]);

  const { user: loggedUser } = useContext(UserContext);
  const navigate = useNavigate();

  const msgList = data?.loggedUserConversations || [];
  if (msgList.length === 0 || error) return null;

  const getOtherUserInfo = (details: UserInfoTypes) => {
    const loggedUserId = loggedUser?.userId;
    if (loggedUserId === details.user1Id) {
      return details.user2Info;
    }
    return details.user1Info;
    // RETURN TYPES
    // user1Info {
    //   userId
    //   avatarImage
    //   userName
    // }
  };

  if (loading) return <CircularProgress size={30} />;
  return (
    <Menu
      anchorEl={anchorEl}
      id="notification-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{ sx: { width: 400, maxHeight: 355, overflowY: "auto" } }}
    >
      {msgList.map((m, index) => {
        const userInfo = getOtherUserInfo(m);
        return (
          <MenuItem key={m.id}>
            <Box
              sx={{ display: "flex", width: "100%" }}
              onClick={() => {
                navigate(`${ROUTES.PROFILE_PAGE}/${userInfo.userId}`);
              }}
            >
              <Avatar src={userInfo.avatarImage || ""} />
              <Box sx={{ px: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography noWrap fontSize={16} color={"primary"}>
                    {userInfo.userName}
                  </Typography>
                  <Badge
                    badgeContent={m.totalUnread}
                    color="error"
                    sx={{ ml: 2 }}
                  ></Badge>
                </Box>
                <Typography noWrap fontSize={12}>
                  {m.messagesInvert[0]?.content || ""}
                </Typography>
              </Box>
            </Box>
            {index !== msgList.length && <Divider />}
          </MenuItem>
        );
      })}
    </Menu>
  );
}

const LATEST_MESSAGE_LIST = gql(`
  query LoggedUserConversations {
    loggedUserConversations {
      id
      user1Id
      user2Id
      createdAt
      updatedAt
      messages{
        id
        content
      }
      messagesInvert{
        id
        content
      }
      totalUnread
      user1Info {
        userId
        avatarImage
        userName
        createdAt
      }
      user2Info {
        userId
        avatarImage
        userName
        createdAt
      }
    }
  }
`);
