import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../CutomButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: 12,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface CustomModalProps {
  title?: string;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  children: React.ReactNode;
  buttonLabel: string;
  isLoading?: boolean;
  modelWidth?: number;
  buttonJustify?: string;
}

export default function CustomModal({
  title,
  isOpen,
  buttonLabel,
  children,
  isLoading,
  handleClose,
  handleSubmit,
  modelWidth,
  buttonJustify,
}: CustomModalProps) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        {title && (
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {title}
          </BootstrapDialogTitle>
        )}
        <DialogContent
          dividers
          sx={{ width: modelWidth || 300, maxWidth: "100%" }}
        >
          {children}
        </DialogContent>
        <DialogActions
          sx={{
            p: "16px 16px !important",
            display: "flex",
            justifyContent: buttonJustify || "right",
          }}
        >
          <CustomButton
            onClick={handleSubmit}
            variant="contained"
            loading={isLoading}
            label={buttonLabel}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
