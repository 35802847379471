import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";
import { lightBackgroundColor } from "../../../utils/themes";

const useStyles = makeStyles()((theme: Theme) => ({
  topPhotosContainer: {
    background:
      theme.palette.mode === "dark"
        ? "#151515"
        : theme.palette.background.default,
    marginBottom: 25,
    borderRadius: "5px",
    width: "100%",
  },
  subtitle: {
    width: "100%",
    padding: "5px 10px",
    fontSize: "20px",
    fontWeight: 500,
    textAlign: "left",
    backgroundColor: lightBackgroundColor,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
    borderRadius: "5px 5px 0 0",
    marginTop: 0,
  },
  info: {
    padding: "10px 15px",
  },

  topPhotos: {
    cursor: "pointer",
  },

  descriptionContainer: {
    width: "100%",
    fontWeight: "bold",
    paddingBottom: 10,
  },
  imageWrapper: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default useStyles;
