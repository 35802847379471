import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      fontSize: 12,
      // padding: "6px 18px",
      // backgroundColor: theme.palette.background.default,
      // color: theme.palette.primary.main,
      // border: `1px solid ${theme.palette.primary.main}`,
      // borderRadius: "20px",
      // textTransform: "capitalize",
      // "&:hover": {
      //   background: bgColor,
      //   border: `1px solid ${bgColor}`,
      //   color,
      // },
    },
  };
});

export default useStyles;
