import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  itemSelected: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingTop: 3,
    paddingLeft: 15,
    paddingBottom: 3,
    color: theme.palette.primary.main,
    fontSize: "16px",
    cursor: "pointer",
  },
  itemNotSelected: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingTop: 3,
    paddingLeft: 15,
    paddingBottom: 3,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    fontSize: "16px",
    cursor: "pointer",
  },
}));

export default useStyles;
