import { useState } from "react";
import * as Yup from "yup";
import { Box, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import CustomModal from "../../components/CustomModal";
import { passwordValidation } from "../../utils/validations";
import LoginContainer from "../../containers/LoginContainer/LoginContainer";
import CustomButton from "../../components/CutomButton";
import ReactHooksInput from "../../components/ReactHooksInput";

import useStyles from "../../components/LoginForm/LoginFromStyles";

const schema = Yup.object()
  .shape({
    email: Yup.string().required().email().label("Email"),
    userName: Yup.string().required().label("UserName"),
    password: passwordValidation("Password"),
  })
  .required();

type FormData = Yup.InferType<typeof schema>;

export default function SignUpPage(): JSX.Element {
  const [showModal, setModal] = useState(false);
  const { classes: s } = useStyles();
  const navigate = useNavigate();

  const [signUp, { loading, error }] = useMutation(SIGN_UP, {
    onCompleted: () => {
      setModal(true);
    },
  });

  function registerUser(values: any): void {
    const info = {
      email: values.email,
      userName: values.userName,
      password: values.password,
      termsAndConditionId: 1,
      privacyPolicyId: 2,
    };
    signUp({
      variables: {
        info,
      },
    });
  }

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitted },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const signUpUser = (values: FormData) => {
    registerUser(values);
  };

  return (
    <LoginContainer>
      <>
        <div className={s.root}>
          <img src={"/logo.svg"} alt="logo" className={s.logo} />
          <form onSubmit={handleSubmit(signUpUser)} className={s.form}>
            <Box sx={{ mb: 1 }}>
              <ReactHooksInput
                label="Email"
                name="email"
                required
                placeholder="Email"
                register={register}
                error={errors.email?.message}
                touched={touchedFields.email || isSubmitted}
                size="lg"
                autoFocus
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <ReactHooksInput
                label="Username"
                name="userName"
                required
                placeholder="Username"
                register={register}
                error={errors.userName?.message}
                touched={touchedFields.userName || isSubmitted}
                size="lg"
              />
            </Box>

            <Box sx={{ mb: 1 }}>
              <ReactHooksInput
                label="Password"
                name="password"
                required
                inputType="password"
                placeholder="Password"
                size="lg"
                register={register}
                error={errors.password?.message}
                touched={touchedFields.password || isSubmitted}
              />
            </Box>

            {error && (
              <div className={s.errorMsg}>{graphqlErrorToMsg(error)}</div>
            )}

            <CustomButton
              className={s.loginBtn}
              variant="contained"
              type="submit"
              loading={loading}
              label="Sign Up"
              size="lg"
              onClick={() => {}}
            />
            <Divider>or</Divider>
            <CustomButton
              className={s.loginBtn}
              variant="outlined"
              loading={loading}
              label="Sign In"
              size="lg"
              onClick={() => {
                navigate("/");
              }}
            />
          </form>
        </div>
        <CustomModal
          isOpen={showModal}
          handleClose={() => navigate("/")}
          handleSubmit={() => navigate("/")}
          buttonLabel="Done"
          modelWidth={400}
          buttonJustify={"center"}
        >
          <Box sx={{ textAlign: "center" }}>
            <h3>Thanks For Registering!</h3>
            <p>
              To complete your registration, please check the email you entered
              and follow the instructions.
            </p>
          </Box>
        </CustomModal>
      </>
    </LoginContainer>
  );
}

const SIGN_UP = gql`
  mutation signUp($info: SignUpInput!) {
    signUp(info: $info) {
      message
    }
  }
`;
