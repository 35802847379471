import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    right: "1px",
    width: "352px",
    backgroundColor: "white",
    borderRadius: "10px 10px 0 0",
    display: "flex",
    flexDirection: "column",
  },
  headerSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: "10px 10px 0 0",
  },
  messageBox: {
    flex: 1,
    padding: "10px",
    minHeight: 300,
    maxHeight: 300,
    overflowY: "auto",
  },
  messageTextBox: {
    display: "flex",
    marginBottom: 5,
  },
}));

export default useStyles;
