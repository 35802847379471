import React, { useEffect, useState } from "react";
import ChatWindow from "./ChatWindow";
import "./ChatApp.css";
import { Grid } from "@mui/material";
import { gql } from "../../__generated__";
import { useQuery, useSubscription } from "@apollo/client";
import { MessageType, ProfileOwnerDetailsType } from "./types";

type ChatWrapperProps = {
  profileOwnerDetails: ProfileOwnerDetailsType;
};

const ChatWrapper: React.FC<ChatWrapperProps> = ({ profileOwnerDetails }) => {
  const [messages, setMessages] = useState<MessageType[] | []>([]);
  const [conversationId, setId] = useState(0);

  const { loading, data: chatList } = useQuery(CONVERSATION_MESSAGES, {
    variables: { userId: profileOwnerDetails.profileOwnerId },
    onCompleted: (apiRes) => {
      // update message list
      const msgList = apiRes.conversationByReceiverUserId.messages
        .filter((m): m is MessageType => m !== null)
        .map((msg) => ({
          id: msg.id,
          content: msg.content,
          senderId: msg.senderId,
          receiverId: msg.receiverId,
        }));
      setMessages(msgList);
      //subscription start after we set the conversationId
      setId(apiRes.conversationByReceiverUserId.id);
    },
  });

  const { data: latestMsg } = useSubscription(RECEIVE_MESSAGES, {
    variables: { conversationId },
    skip: !conversationId,
  });

  useEffect(() => {
    if (latestMsg?.receiveMessageByConversation) {
      const { id, content, senderId, receiverId } =
        latestMsg.receiveMessageByConversation;
      const newMsg = {
        id,
        content,
        senderId,
        receiverId,
      };
      if (newMsg.id !== messages[messages.length - 1].id) {
        setMessages((msgs) => [...msgs, newMsg]);
      }
    }
  }, [latestMsg, messages]);

  if (loading) return null;

  return (
    <Grid container className="app" style={{ position: "relative" }}>
      <ChatWindow
        unreadCount={chatList?.conversationByReceiverUserId.totalUnread || 0}
        messages={messages}
        profileOwnerDetails={profileOwnerDetails}
      />
    </Grid>
  );
};

export default ChatWrapper;

const CONVERSATION_MESSAGES = gql(`
  query ConversationByReceiverUserId($userId: Int!) {
    conversationByReceiverUserId(userId: $userId) {
      createdAt
      id
      totalUnread
      messages {
        id
        content
        receiverId
        senderId
      }
      user1Id
      user2Id
      user1Info {
        userId
        userName
        avatarImage
      }
      user2Info {
        userId
        userName
        avatarImage
      }
    }
  }
  `);

const RECEIVE_MESSAGES = gql(`
  subscription ReceiveMessageByConversation($conversationId: Int!) {
    receiveMessageByConversation(conversationId: $conversationId) {
      id
      conversationId
      senderId
      receiverId
      content
      isRead
      createdAt
      updatedAt
      senderInfo {
        userId
        userName
        avatarImage
      }
      receiverInfo {
        userId
        userName
        avatarImage
      }
    }
  }`);
