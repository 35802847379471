import { Navigate, Outlet, useLocation } from "react-router-dom";
import ROUTES from "./routes";
import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";

export default function ProtectedRoute() {
  const { data, loading } = useQuery(ME);
  const location = useLocation();

  if (loading) return null;

  if (data?.me.profileCompleted) {
    return <Outlet />;
  }
  if (data?.me.userId) {
    <Navigate
      to={{
        pathname: ROUTES.COMPLETE_SIGN_IN,
      }}
    />;
  }

  localStorage.clear();
  return (
    <Navigate
      to={{
        pathname: "/",
        search: `?from=${encodeURIComponent(location.pathname)}`,
      }}
    />
  );
}

const ME = gql(`
  query UserDetails {
    me {
      email
      profileCompleted
      userId
      isModerator
      isAdmin
      profile {
        avatarImage
        userName
        createdAt
        dob
        firstName
        gender
        lastName
        updatedAt
        userId
      }
    }
  }`);
