import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { lightBackgroundColor } from "../../utils/themes";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    links: {
      padding: "8px !important",
    },
    linkWrapper: {
      backgroundColor: lightBackgroundColor,
      borderRadius: 8,
      paddingLeft: 15,
      paddingRight: 15,
    },
  };
});

export default useStyles;
